<template>
  <div :class="['lp-container', {'padding-has-caution': isHasCaution}]">
    <div v-if="detailLP" v-html="detailLP.content"></div>
  </div>
</template>

<script>

import store from '@/store'
import { mapState } from 'vuex'
import Common from '@/mixins/common.mixin'
export default {
  name: 'Lp',
  mixins: [Common],

  beforeRouteEnter (to, from, next) {
    // remove /lp/ (4 character) hard defined in router
    const params = {
      name: to.path.substring(4)
    }
    // call api
    store.dispatch('landingPage/getDetail', params).then(_ => next())
  },

  computed: {
    ...mapState('landingPage', ['detailLP']),
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  mounted () {
    if (this.detailLP) {
      // import js
      if (this.detailLP.js_resource.length > 0) {
        this.detailLP.js_resource.forEach((item) => {
          const js = document.createElement('script')
          js.type = 'text/javascript'
          js.src = item
          document.head.appendChild(js)
        })
      }

      // import css
      if (this.detailLP.css_resource.length > 0) {
        this.detailLP.css_resource.forEach((item) => {
          const style = document.createElement('link')
          style.rel = 'stylesheet'
          style.href = item
          document.head.appendChild(style)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lp-container {
  @media #{$info-phone} {
    padding-top: 50px
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding-top: 100px
    }
  }
}
</style>
